import React from "react";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import Image from "../components/Image";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

SwiperCore.use([Navigation]);

const Slider = ({ slides, link, lightbox }) => {
  return (
    <>
      <Swiper
        className="lg:md:container md:mx-auto h-full"
        spaceBetween={48}
        navigation={{
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        }}
        slidesPerView={`auto`}
        loop={slides.length > 1 ? true : false}
        onSlideChange={(e) => {}}
      >
        {slides.map((slide, i) => {
          return (
            <SwiperSlide
              key={slide.id || i}
              className="relative object-cover h-[250px!important] md:h-[400px!important] md:w-[560px!important]"
            >
              <Image image={slide} className="w-full h-full" />
            </SwiperSlide>
          );
        })}
        {slides.length > 1 && (
          <div className="absolute z-10 bottom-0 left-0 flex text-white bg-cinnabar-500/75">
            <div
              className={`swiper-prev group cursor-pointer px-2 py-3 font-bold text-xl hover-hover:hover:bg-cinnabar-500`}
            >
              <BsChevronLeft />
            </div>
            <div
              className={`swiper-next group cursor-pointer px-2 py-3 font-bold text-xl hover-hover:hover:bg-cinnabar-500`}
            >
              <BsChevronRight />
            </div>
          </div>
        )}
      </Swiper>
    </>
  );
};

export default Slider;
