import React from "react";
import { graphql } from "gatsby";

import Layout from "components/commercial/Layout";
import HeroImage from "components/HeroImage";
import NextSection from "components/NextSection";
import SingleSection from "components/SingleSection";
import Slider from "components/Slider";
import Image from "components/Image";

export const query = graphql`
  {
    wp {
      page(id: "/commercial/about/", idType: URI) {
        id
        title
        slug
        commercialAbout {
          heroImage {
            ...MediaItem
          }
          panelOne {
            image {
              ...MediaItem
            }
            text
            title
          }
          panelThree {
            contactDetails {
              email
              name
              phone
              title
            }
            link {
              title
              url
              target
            }
            image {
              ...MediaItem
            }
            subtitle
            text
            title
          }
          panelTwo {
            slider {
              ...MediaItem
            }
            text
            title
          }
          nextSection {
            number
            label
            link {
              ... on WP_Page {
                id
                uri
              }
            }
            image {
              ...MediaItem
            }
            heading
          }
        }
      }
    }
  }
`;

const About = ({ data }) => {
  const { title } = data.wp.page;
  const fields = data.wp.page.commercialAbout;
  const slides = fields.panelTwo.slider;
  return (
    <Layout title={title}>
      <main>
        <div className="relative h-[calc(100vh - 92px)] md:h-[75vh] overflow-y-hidden">
          <h1 className="sr-only">{data.title}</h1>
          <div className="absolute top-0 left-0 bottom-0 z-0 h-full w-full md:w-7/12 lg:w-[45%] xl:w-7/12 bg-everglade"></div>
          <div className="md:container md:mx-auto pt-20 md:pt-32">
            <HeroImage
              description={fields.panelOne.title}
              image={fields.heroImage}
              className={`top-[-8px]`}
            />
          </div>
        </div>
        <SingleSection
          image={fields.panelOne.image}
          description={fields.panelOne.text}
        />
        <div className="md:container md:mx-auto flex flex-col md:flex-row justify-start md:justify-end items-start md:items-center px-6 md:pr-0 lg:pl-0">
          <div className="w-full md:w-2/6 max-w-[500px] space-y-4 text-everglade-500 mb-8 md:mb-0">
            <div className="mt-4 font-bold text-everglade uppercase text-3xl tracking-wider">
              {fields.panelTwo.title}
            </div>
            <div
              className="font-light"
              dangerouslySetInnerHTML={{ __html: fields.panelTwo.text }}
            ></div>
          </div>
          <div className="w-full md:w-4/6 max-w-[830px] md:pl-20 lg:pl-36">
            {slides && slides.length > 1 ? (
              <Slider slides={slides} />
            ) : (
              <Image
                image={slides[0]}
                className={`w-80 h-80 lg:w-[500px] lg:h-[500px] octo object-cover`}
              />
            )}
          </div>
        </div>
        <SingleSection
          image={fields.panelThree.image}
          sololabel={fields.panelThree.subtitle}
          heading={fields.panelThree.title}
          description={fields.panelThree.text}
          link={fields.panelThree.link && fields.panelThree.link.url}
          detailsName={fields.panelThree.contactDetails.name}
          detailsPosition={fields.panelThree.contactDetails.title}
          detailsPhone={fields.panelThree.contactDetails.phone}
          detailsEmail={fields.panelThree.contactDetails.email}
        />
        <NextSection
          number={fields.nextSection.number}
          label={fields.nextSection.label}
          heading={fields.nextSection.heading}
          background="bobalife"
          link={fields.nextSection.link.uri}
          image={
            fields.nextSection.image
              ? fields.nextSection.image
              : fields.heroImage
          }
        />
      </main>
    </Layout>
  );
};

export default About;
