import React from "react";
import { BsArrowDown } from "react-icons/bs";

import { zeroPad } from "../../lib/mathUtils";
import Image from "../components/Image";

const HeroImage = ({
  number = "1",
  label = "About us",
  description = "",
  image,
  className,
}) => {
  if (!image) {
    return null;
  }
  return (
    <div className="relative md:min-h-[640px] lg:min-h-[0] h-[calc(100vh-172px)] md:h-[calc(75vh-120px)] flex flex-col justify-between">
      <div
        className={`relative z-10 flex-1 h-full md:-mr-16 flex flex-col justify-between bg-transparent md:bg-everglade-500/80 text-white w-full md:w-1/2 lg:w-1/3 py-6 md:py-12 px-8 lg:px-16 ${className}`}
      >
        <div className="flex items-center text-sm text-cinnabar mb-12 md:mb-28">
          {zeroPad(number, 2)}
          <span className="inline-block mx-2 w-11 bg-cinnabar h-[1px]"></span>
          {label}
        </div>
        {description && (
          <div className="text-[40px] font-bold uppercase tracking-wider leading-tight">
            {description}
          </div>
        )}
        <BsArrowDown className="mt-auto h-8 w-8 text-cinnabar-500" />
      </div>
      <Image
        image={image}
        className="md:absolute object-cover object-center top-0 left-0 bottom-0 z-0 w-full h-[400px] md:h-full"
      />
    </div>
  );
};

export default HeroImage;
