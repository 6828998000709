import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import GlobalLayout from "components/GlobalLayout";
import Nav from "components/Nav";
import MobileNav from "components/MobileNav";
import Footer from "components/Footer";
import ContactBar from "components/ContactBar";

const CommercialMenu = () => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          menu(idType: NAME, id: "Commercial Menu") {
            id
            menuItems {
              nodes {
                id
                label
                path
              }
            }
          }
          themeGeneralSettings {
            globalOptions {
              contactBannerMessage
              instagram
              linkedin
              twitter
              phone
              email
              address
            }
          }
        }
      }
    `
  );
  return data.wp;
};
const Layout = ({ title, page, children }) => {
  const data = CommercialMenu();

  return (
    <GlobalLayout title={title}>
      <Nav
        theme={`commercial`}
        menu={data.menu}
        logoLink="/commercial"
        instagram={data.themeGeneralSettings.globalOptions.instagram}
        twitter={data.themeGeneralSettings.globalOptions.twitter}
        linkedin={data.themeGeneralSettings.globalOptions.linkedin}
      />
      <MobileNav theme={`commercial`} menu={data.menu} logoLink="/commercial" />
      {children}
      <ContactBar
        theme={`cinnabar`}
        link={`/commercial/contact`}
        contactBannerMessage={
          data.themeGeneralSettings.globalOptions.contactBannerMessage
        }
      />
      <Footer
        page={page}
        menu={data.menu}
        theme={`commercial`}
        address={data.themeGeneralSettings.globalOptions.address}
        email={data.themeGeneralSettings.globalOptions.email}
        phone={data.themeGeneralSettings.globalOptions.phone}
        instagram={data.themeGeneralSettings.globalOptions.instagram}
        twitter={data.themeGeneralSettings.globalOptions.twitter}
        linkedin={data.themeGeneralSettings.globalOptions.linkedin}
      />
    </GlobalLayout>
  );
};

export default Layout;
