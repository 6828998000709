import React from "react";

import { zeroPad } from "../../lib/mathUtils";

const NumberedLabel = ({ color, number, label }) => {
  const textColor = color ? `text-${color}` : "text-cinnabar";
  const bgColor = color ? `bg-${color}` : "bg-cinnabar";

  return (
    <div className={`flex items-center text-sm ${textColor}`}>
      {zeroPad(number, 2)}
      <span className={`inline-block mx-2 w-11 h-[1px] ${bgColor}`}></span>
      {label}
    </div>
  );
};

export default NumberedLabel;
