import React from "react";
import { Link } from "gatsby";

import setTheme from "../../lib/theme";
import LogoShape from "../images/LogoShape";
import NumberedLabel from "./NumberedLabel";
import Image from "./Image";

const NextSection = ({
  number = 1,
  label = "About us",
  heading = "Built to exceeding expectations",
  image,
  link = "#",
  background = "bobalife",
}) => {
  return (
    <div
      className={`relative overflow-hidden py-24 ${setTheme[background]} bg-skin-fill`}
    >
      <LogoShape
        fill={"#fff"}
        className="absolute hidden lg:block z-20 opacity-70 w-[25%] h-auto right-[15%] transform rotate-45 translate-y-[-60%]"
      />
      <div className="md:container mx-6 md:mx-auto px-6 xl:px-0">
        <NumberedLabel color="cinnabar" number={number} label={label} />
        <div className="relative flex flex-col md:flex-row items-center justify-center">
          <Link href={link} className="md:-mr-16">
            <div className="relative z-10 flex flex-col items-center pt-12 pb-8 px-12 w-80 h-80 md:w-88 md:h-88 lg:w-96 lg:h-96 -bottom-24 md:bottom-0 justify-between bg-everglade-500 hover:bg-cinnabar-500 transition duration-300 text-white octo text-center">
              <div className="text-3xl m-auto font-bold uppercase tracking-wider leading-tight">
                {heading}
              </div>
              <p className="mt-auto">Read More</p>
            </div>
          </Link>

          {image && (
            <div className="relative z-0">
              <figure
                className={`w-[350px] h-[350px] md:w-[450px] md:h-[450px] lg:w-[500px] lg:h-[500px]`}
              >
                <Image
                  image={image}
                  className={`octo object-cover w-full h-full`}
                />
              </figure>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NextSection;
