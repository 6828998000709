import React from "react";

import { zeroPad } from "../../lib/mathUtils";
import LinkInternal from "./LinkInternal";
import Image from "../components/Image";

const SingleSection = ({
  number,
  order = "1",
  label,
  sololabel,
  heading,
  description,
  link,
  image,
  detailsName,
  detailsPosition,
  detailsEmail,
  detailsPhone,
  className,
  descriptionClass,
}) => {
  if (!image) {
    return null;
  }
  return (
    <div
      className={`lg:container lg:px-4 mx-6 lg:mx-auto flex flex-col items-center md:flex-row justify-center gap-4 md:gap-16 lg:gap-32 ${
        className ? className : "my-16 lg:my-32"
      }`}
    >
      <figure className={`md:w-[500px] md:h-[500px] relative ${order}`}>
        {/* <img
          className={`octo object-cover w-full h-full`}
          src={"https://picsum.photos/400"}
          alt=""
        /> */}
        <Image
          image={image}
          className={`w-80 h-80 lg:w-[500px] lg:h-[500px] octo object-cover`}
        />
        {detailsName && (
          <div className="octo z-10 absolute flex flex-col justify-center items-center right-[-25px] lg:right-[-59px] bottom-0 right-0 md:bottom-16 lg:bottom-0 bg-everglade-500 h-[196px] w-[196px] text-white">
            <p className="text-center">
              <span className="font-bold">{detailsName}</span>
              <br />
              {detailsPosition}
              <br />
              <a
                href={`tel:${detailsPhone}`}
                className="transition duration-300 mt-2 inline-block leading-5 hover:text-cinnabar-500"
              >
                <span className="text-cinnabar-500 leading-5">T: </span>
                {detailsPhone}
              </a>
              <br />
              <a
                href={`mailto:${detailsEmail}`}
                className="text-cinnabar-500 hover:text-white transition duration-300 underline"
              >
                Email
              </a>
            </p>
          </div>
        )}
      </figure>
      <div className="flex flex-0 flex-col justify-center order-1">
        {number && label && (
          <div className="flex items-center text-sm text-cinnabar">
            {zeroPad(number + 1, 2)}
            <span className="inline-block mx-2 w-11 bg-cinnabar h-[1px]"></span>
            {label}
          </div>
        )}

        {sololabel && (
          <div className="flex items-center text-sm text-cinnabar">
            {sololabel}
          </div>
        )}

        <h3 className="mt-4 font-bold text-everglade uppercase max-w-[500px] text-3xl tracking-wider">
          {heading}
        </h3>
        <div
          className={`lg:mt-4 text-everglade font-light max-w-[500px] ${descriptionClass}`}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        {link && (
          <div className="mt-4 text-cinnabar">
            <LinkInternal
              target={link}
              label={`Read more`}
              theme={`cinnabar`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleSection;
